<template>
  <v-container class="containerMargin">
    <v-row>
      <v-col cols="12" align="center" justify="center">
        <p class="display-4">404</p>
      </v-col>
      <v-col cols="12" align="center" justify="center">
        <p>The page you requested could not be found.</p>
      </v-col>
      <v-col cols="12" align="center" justify="center">
        <v-btn class="ma-2 back" outlined color="blue darken-1" @click="goBack">
          <i class="material-icons">keyboard_backspace</i>BACK
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import router from "@/routes/router";

export default {
  name: "notFound",
  methods: {
    goBack() {
      router.go(-2);
    }
  }
};
</script>

<style lang="scss" scoped>
.containerMargin {
  margin-top: 10em;
  .back {
    &:hover {
      color: #ffffff !important;
      background-color: #42a5f5;
    }
  }
}
</style>